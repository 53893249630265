<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import 'ol/ol.css'
import * as ol from 'ol'
import * as layer from 'ol/layer'
import * as source from 'ol/source'
import * as proj from 'ol/proj'
import * as geom from 'ol/geom'
import * as style from 'ol/style'
import Overlay from 'ol/Overlay'
import CircleStyle from 'ol/style/Circle'
import LayerSwitcher from 'ol-layerswitcher'
import json from '@/assets/upload/file/route_data_1.JSON'
import Layout from '@/views/layouts/main2'
import i18n from '@/i18n'
import BlockUI from '@/components/BlockUI'
import simplebar from 'simplebar-vue'
import Swal from 'sweetalert2'
import service from '@/server/http_service'
import moment from 'moment'
import global_func from '@/global_function'
import PlanDetail from '@/components/modal/plans_detail'
import ModalCompany from '@/components/modal/company'
import ModalReview from '@/components/modal/review'
import ModalProof from '@/components/modal/proof_of_carrier'
import rhm from '@/assets/js/routing_here_map.js'
 
export default {
  page: {
    title: 'ติดตามสินค้า',
    meta: [{ name: 'description' }],
  },
  components: { Layout, BlockUI, simplebar, PlanDetail, ModalCompany, ModalReview, ModalProof },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('tracking_goods')
    const src = require('../../../assets/images/ol/location_no_1.png')
    const src2 = require('../../../assets/images/ol/location_no_2.png')
    return {
      // bank - dev
      star_flag:false,
      fv_flag:false,
      pinPointSource: null,
      p_of_plan_data: null,
      data_flag:false,
      group_id:null,
      img_marker: null,
      select_items:[],
      select_items2:[],
      click_select_items:[],
      marker_icon:null,
      time_set:null,
      base_layer : null,
      // genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      check_lang: true,
      lang: lang_obj,
      block_msg: 'กรุณาเลือกแผนงานที่ต้องการติดตาม',
      url_params_search_flag: true,
      // sort data
      sort_by_plans_status: '0',
      search_plans_text: '',
      // goods data
      dataGoodsType_master: [],
      dataGoodsType_data: [],
      // truck data
      dataTruckType: [],
      dataTruckTypeGroup: [],
      // flg data
      block_ui_flg: true,
      active_plan_detail: false,
      // plans data
      plans_tracking_raw: [],
      plans_tracking_data: [],
      plan_tracking_select: [],
      // timeline
      arrlocation: [
        {
          name: 'point_1',
          date_appointment : '2021-08-25 23:59:59',
        },
      ],
      // map
      plan_data_selcet: {
        conv_price: '235.00',
        goods_type_name: 'สินค้าอิเล็คทรอนิคสินค้าอิเล็คทรอนิค2',
        goods_type_name_full: 'สินค้าอิเล็คทรอนิคสินค้าอิเล็คทรอนิค2',
        pickup_time: '30/07/2021 (ตลอดทั้งวัน)',
        status_name: 'รอการตอบรับ',
        truck_type_group: 1,
        truck_type_name: '4 ล้อ - คอก, ...',
      },
      heightMap: '800px', // กำหนดความสูงด้วย methods getHeightDivMap()
      map: null,
      center: null,
      pick_up_1: null, // dtc สำนักงานใหญ่
      drop_1: null, // mega bangna
      zoom: 15,
      rotation: 0,
      src: src,
      src2: src2,
      coordinates: [11207429.40090933, 13541935.007697027],
      features: [
        {
          type: 'Feature',
          id: 'one',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [11207429.40090933, 13541935.007697027],
          },
        },
      ],
      pinPointLayer: null,
      directionLayer: [], // layer ของเส้นทางจากจุดรับส่ง
      directions_data: [], // ข้อมูลเส้นทางที่ได้จาก api
      data_location: [
        {
          id: 1,
          icon: 'ri-map-pin-fill',
          texts: '',
          texts_en: '',
          LAT: '',
          LON: '',
          location_name: '',
          location_addr: '',
          cood: null,
          iconLocation: null,
        },
        {
          id: 2,
          icon: 'ri-map-pin-fill',
          texts: '',
          texts_en: '',
          LAT: '',
          LON: '',
          location_name: '',
          location_addr: '',
          cood: null,
          iconLocation: null,
        },
      ],
      activeLocation_index: '',
      total_distance: 0,

      subscribe_data: [],
    }
  },
  watch: {},
  async mounted() {
    window.addEventListener('wheel', this.bank_check_scroll)
    await this.getDataGoodsType()
    await this.getTruckType()
    await this.arrBaseLayer()
    await this.initiateMap()
    await this.getPlansTracking()
    await this.getSubscribeData()
    this.clearAllInterval()
  },
  methods: {
    bank_check_scroll(){
      if(this.$refs.bankscrol != undefined) {
        if(this.$refs.bankscrol.scrollElement.scrollTop > 0){
          this.activeScroll = true
        }else{
          this.activeScroll = false
        }
      }
    },
    toTop() {
      this.$refs.bankscrol.scrollElement.scrollTo({ top: 0, behavior: 'smooth' })
    },
    clearData(){
      clearInterval(this.time_set)
      if (this.marker_icon) {
        this.map.removeLayer(this.marker_icon)
      }
    },
    clearAllInterval() {
      const intervalId = window.setInterval(() => {}, Number.MAX_SAFE_INTEGER)
      for (let i = 1; i < intervalId; i++) {
        window.clearInterval(i)
      }
    },
    async startRealtime(){
      clearInterval(this.time_set)
      await this.dataRealtime()
      this.time_set = setInterval(async() => {
        await this.dataRealtime()
      }, 30000)
    },
    async dataRealtime(){
      try {
        // console.log(this.click_select_items)
        // let api = await this.axios.post('https://gps.dtc.co.th:8099//getRealtimeData',{
        // let api = await this.axios.post('http://devweb.dtcgps.com:8087/getRealtimeData',{
        //   'api_token_key': 'KSYZSP24XLC3RX3BEPZW72GV1J9QAGMWNHUM89T765TRYE1DUKLB4FJ8HFD5QC6A',
        //   'gps_list': [this.click_select_items],
        // },
        // {
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },  
        // },
        // )

        service.postService('getTrackingVehicleDTC', {
          account_lang: this.account_lang,
          member_data: this.account_data,
          blackbox_id: this.click_select_items,
          id_member_carrier: this.select_items2.plan_all_data.id_member_carrier,
        }).then(rp => {
          // console.log(rp)
          if(rp.code == '200'){
            if (this.marker_icon) {
              this.map.removeLayer(this.marker_icon)
            }
            // console.log(api)
            // console.log('lat : ',api.data.data[0].lat)
            // console.log('lon : ',api.data.data[0].lon)
            let realtime = rp.data[0]
            if(realtime != undefined) {
              // console.log('lat : ', realtime.r_lat)
              // console.log('lon : ', realtime.r_lon)
              let lat = parseFloat(realtime.r_lat) 
              let lon = parseFloat(realtime.r_lon)
              if (this.marker_icon != null) {
                this.map.removeLayer(this.marker_icon)
              }
              var new_point = new ol.Feature({
                geometry: new geom.Point(proj.fromLonLat([lon, lat])),
              })
              if(this.group_id == 1){
                this.img_marker = require('../../../assets/images/truck_img/4_wheels.png')
              }else if(this.group_id == 2){
                this.img_marker = require('../../../assets/images/truck_img/6_wheels.png')
              }else if(this.group_id == 3){
                this.img_marker = require('../../../assets/images/truck_img/10_wheels.png')
              }else if(this.group_id == 4){
                this.img_marker = require('../../../assets/images/truck_img/trailer2.png')
              }else if(this.group_id == 5){
                this.img_marker = require('../../../assets/images/truck_img/trailer.png')
              }else if(this.group_id == 6){
                this.img_marker = require('../../../assets/images/truck_img/tow_truck.png')
              }
              // console.log(this.group_id)
              new_point.setStyle(
                new style.Style({
                  image: new style.Icon({
                    scale: 0.3,
                    anchor: [0.5, 1],
                    src: this.img_marker,
                  }),
                }),
              )
           
              this.marker_icon = new layer.Vector({
                source: new source.Vector({
                  features: [new_point],
                }),
              })
              this.marker_icon.setZIndex(999)
              this.map.addLayer(this.marker_icon)
            
              // this.map.getView().animate({
              //   center: proj.fromLonLat([lon, lat]),
              //   zoom: 10,
              // })
            }
          }
        })


      } catch (error) {
        console.log(error)
      }
        
    },
    updateStar(val, index){
      // console.log(val)
      // // console.log(val)
      var that = this
      let mode = 'unsub'
      if(val.star_flag == '0') {
        mode = 'sub'
      }

      if(typeof index !== 'undefined') {
        if (this.plan_tracking_select[index].star_flag == '0') {
          this.plan_tracking_select[index].star_flag = '1'
        } else {
          this.plan_tracking_select[index].star_flag = '0'
        }
      }

      service
        .postService('updateSubscribe', {
          account_data : this.account_data,
          provider_id : val.plan_all_data.id_member_carrier,
          mode : mode,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getSubscribeData()
            // setTimeout(() => {
            //   that.getPlansTracking()
            // }, 100)
          }
        })
    },
    update_favorite(option,type){
      if(type != 'in'){
        service
          .postService('setBookmarkPlan', {
            mode:'order',
            plan_code : option.plan_code,
          })
          .then(rp => {
            if(rp.code == '200'){
              this.getPlansTracking()
            }
          })
      }else{
        this.fv_flag = !this.fv_flag
        service
          .postService('setBookmarkPlan', {
            mode:'order',
            plan_code : option.plan_code,
          })
          .then(rp => {
            if(rp.code == '200'){
              this.getPlansTracking()
            }
          })
      }
    },
    // --- get data part ---
    getDataGoodsType() {  // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          var obj2 = JSON.stringify(goods_type_sel)
          var obj3 = JSON.parse(obj2)
          this.dataGoodsType_master.push(obj3) // for insert data to db
          this.dataGoodsType_data.push(obj3)
          if (this.account_lang != 'th') {
            this.check_lang = false
          }
        }
        // // console.log(this.dataGoodsType_data)
      })
    },
    getTruckType() { // get ประเภทรถบรรทุก
      service.postService('getTruckType').then(rp => {
        // // console.log(rp)
        this.dataTruckType = rp.data
        // // console.log(this.dataTruckType)
      })
    },
    getPlansTracking() {
      var that = this
      var sort_data = this.sort_by_plans_status
      var code = this.search_plans_text
      service
        .postService('getPlansTracking', {
          user_data: this.account_data[0],
          sort_by_plans_status: sort_data,
          plan_code: code,
        })
        .then(rp => {
          // // console.log(rp)
          this.plans_tracking_raw = rp.data
          this.createPlansTracking()
        })
    },
    arrBaseLayer(){
      // this.base_layer = [{"rr":"1"}]
      var arr = []
      var P_map = {}
      var dee_map_th2 = new layer.Tile({
        title: 'Dee-MapV2 (Thai)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?', 
          params: {
            'customer_key': '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_th2)
      // // console.log(arr)
      P_map.dee_map_th2 = dee_map_th2

      var dee_map_en2 = new layer.Tile({
        title: 'Dee-MapV2 (En)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?',
          params: {
            customer_key: '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map_EN',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_en2)
      P_map.dee_map_en2 = dee_map_en2

      var layerNokiaHere = new layer.Tile({
        title: 'HereMap',
        type: 'base',
        visible: false,
        maxZoom: 18,
        source: new source.XYZ({
          url: 'http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG&xnlp=CL_JSMv2.5.3,SID_A78335BD-4DDE-4BBB-B544-B856888E9E89',
        }),
      })
      arr.unshift(layerNokiaHere)
      P_map.layerNokiaHere = layerNokiaHere

      var satellite = new layer.Tile({
        title: 'Satellite',
        type: 'base',
        visible: false,
        maxZoom: 20,
        source: new source.XYZ({
          url: 'http://1.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG',
        }),
      })
      arr.unshift(satellite)
      P_map.satellite = satellite

      var openstreetmap = new layer.Tile({
        // A layer must have a title to appear in the layerswitcher
        title: 'OSM',
        // Again set this layer as a base layer
        type: 'base',
        visible: false,
        source: new source.OSM(),
      })
      arr.unshift(openstreetmap)
      P_map.openstreetmap = openstreetmap

      this.base_layer = {arr: arr, POINT: P_map}
    },
    // set tracking display
    initiateMap() {
      var map = new ol.Map({
        target: this.$refs.map_1,
        // layers: [
        //   new layer.Tile({
        //     source: new source.OSM(),
        //   }),
        // ],
        view: new ol.View({
          center: proj.fromLonLat([100.60379663561206, 13.676865941765966]),
          zoom: 10,
        }),
      })
      this.pinPointSource = new source.Vector({
        features: [],
      })
      //put point on layer
      this.pinPointLayer = new layer.Vector({
        name: 'pin_point',
        source: this.pinPointSource,
      })
      // this.mark_s.setZIndex(999)
      

      var arr_vector_layer = []
      this.directionSource = new source.Vector({
        features: [],
      }),
      this.directionLayer = new layer.Vector({
        title: 'route',
        name: 'route',
        source: this.directionSource,
      })
      arr_vector_layer.push(this.directionLayer)
      // set name of vector for easy finding after
      //vectorLayer.set('name', 'route')
      // map.addLayer(this.directionLayer)
      this.map = map

      var group_overlay_feature = new layer.Group({
        // A layer must have a title to appear in the layerswitcher
        'title': 'Feature',
        fold: 'close',
        layers: arr_vector_layer,
      })
      
      this.map = map
      var arr_layer = this.base_layer.arr
      this.base_layer.POINT['openstreetmap'].setVisible(true)
      var group_base = new layer.Group({
        // A layer must have a title to appear in the layerswitcher
        'title': 'Base Layer',
        fold: 'open',
        layers: arr_layer,
      })
      
      this.map.addLayer(group_overlay_feature)
      this.map.addLayer(group_base)
      // this.marker_icon.setZIndex(999)
      this.directionLayer.setZIndex(980)
      this.pinPointLayer.setZIndex(990)
      map.addLayer(this.pinPointLayer)

      var layerSwitcher = new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'group',
      })
      map.addControl(layerSwitcher)

    },
    createPlansTracking() {
      const data = this.plans_tracking_raw
      // // console.log('pi')
      // // console.log(data)
      this.plans_tracking_data = []
      if(data.length > 0) {
        var data_push = {}
        var data_detail = {}
        var data_carrier_id = []
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          // console.log('plans tracking', element)
          data_detail = {
            id_order_tracking : element.id_order_tracking,
            created : element.created,
            order_period : moment(element.period).format('YYYY-MM-DD HH:mm'),
            period_display : moment(element.period).format('dd DD/MM/YYYY HH:mm'),
            good_type_name : this.getGoodsTypeName(element.goods_type_id),
            quantity : element.quantity,
            question : element.question,
            arrival_address : element.arrival_address,
            departure_address : element.departure_address,
            total_distance : element.total_distance,
            vehicle_type_group_id : element.vehicle_type_group_id,
            truck_type_name : this.findTruckTypeName(element.vehicle_type_id),
            other_service_display : element.other_service_display,
            expected_price : element.expected_price,
            arrival_lat_lon : element.arrival_lat_lon,
            departure_lat_lon : element.departure_lat_lon,
            pickup_time_1 : element.pickup_time_1,
            pickup_time_2 : element.pickup_time_2,
          }
          data_push = {
            plan_all_data: element,
            plan_code: element.id_order_tracking,
            order_status: element.order_status,
            order_status_name: this.getPlanStatus(element.order_status),
            departure_time: moment(element.departure_time).format('YYYY-MM-DD HH:mm'),
            goods_type_name: this.getGoodsTypeName(element.goods_type_id),
            two_point_name: this.findTwoPoint(element.departure_address, element.arrival_address),
            truck_type_group_id: element.vehicle_type_group_id,
            truck_type_id: element.vehicle_type_id,
            truck_type_name: this.findTruckTypeName(element.vehicle_type_id),
            carrier_name : element.carrier_name,
            data_detail: data_detail,
            bookmark_flg:element.bookmark_flg,
            bookmark_flg_time:element.bookmark_flg_time,
            track_data: element.track_data,
            star_flag: this.findStarFlag(element.id_member_carrier),
          }
          // console.log('plan tracking')
          this.plans_tracking_data.push(data_push)
          data_carrier_id.push(element.id_member_carrier)
        }
        let that = this
        setTimeout(function() {
          that.getAllProfilePicture(data_carrier_id)
        }, 500)
        var urlParams = new URLSearchParams(window.location.search)
        if(urlParams.get('data') != null && this.url_params_search_flag){
          this.url_params_search_flag = false
          const found = this.plans_tracking_data.find(element => element.plan_code == urlParams.get('data'))
          this.openPlanDetail(found)
        }
      }
    },
    // --- แปรข้อมูลจาก raw data and แปรค่าข้อมูล ---
    getGoodsTypeName(goodsTypeId) { // นำ goods_type_id มาหาชื่อของประเภทสินค้า
      var data_return = ''
      if(goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // // console.log(res)
        // // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          if(count_goods_type < 2) {
            for (let index = 0; index < this.dataGoodsType_data.length; index++) {
              const element = this.dataGoodsType_data[index]
              if (element.goods_type_id == goodsType_id) {
                if (data_return != '') {
                  data_return += ', '
                  data_return += element.goods_type_name_th
                  if(!this.check_lang){
                    data_return += element.goods_type_name_en
                  }
                } else {
                  data_return = element.goods_type_name_th
                  if(!this.check_lang){
                    data_return += element.goods_type_name_en
                  }
                }
              }
            }
            count_goods_type++
          } else {
            data_return += ', ...'
          }
        }
      } else {
        data_return = '-'
      }
      // // console.log(data_return)
      return data_return
    },
    getGoodsTypeNameFull(goodsTypeId) { // นำ goods_type_id มาหาชื่อของประเภทสินค้า แบบเต็ม
      var data_return = ''
      if(goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0

        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          for (let index = 0; index < this.dataGoodsType_data.length; index++) {
            const element = this.dataGoodsType_data[index]
            if (element.goods_type_id == goodsType_id) {
              if (data_return != '') {
                data_return += ', '
                data_return += element.goods_type_name_th
                if(!this.check_lang){
                  data_return += element.goods_type_name_en
                }
              } else {
                data_return = element.goods_type_name_th
                if(!this.check_lang){
                  data_return += element.goods_type_name_en
                }
              }
            }
          }
        }
      } else {
        data_return = '-'
      }
      return data_return
    },
    getPlanStatus(order_status) { // เช็คสถานะของ raw data ของ แผนงานนั้นๆ จาก order_status
      var data_return = ''
      if(order_status == '1') {
        data_return = 'รอเสนองาน'
      } else if(order_status == '2') {
        data_return = 'รอดำเนินการ'
      } else if(order_status == '3') {
        data_return = 'อยู่ระหว่างขนส่ง'
      } else if(order_status == '4') {
        data_return = 'ดำเนินการเสร็จแล้ว'
      } else if(order_status == '5') {
        data_return = 'ปิดงาน'
      }
      return data_return
    },
    getDateTime(datetime) { // แปลงเวลาเป็น format สำหรับแสดงบนเว็บ
      var ck1 = datetime !== null && datetime != null && datetime != undefined
      var data_return = this.lang.no_data_time
      if(ck1) {
        data_return = moment(datetime).format('dd DD/MM/YYYY HH:mm')
      }
      return data_return
    },
    getPickupTime(time1, time2) {
      var dateData = global_func.Between2Date(time1, time2)
      var diffTime = (dateData.diffTime / 1000 / 60 / 60)
      var date_return = ''
      if(diffTime > 23.9997222222 && diffTime < 24) {
        date_return = moment(time1).format('dd DD/MM/YYYY') + ' ' + '(ตลอดทั้งวัน)'
      } else {
        date_return = moment(time1).format('dd DD/MM/YYYY HH:mm') + ' - ' + moment(time2).format('dd DD/MM/YYYY HH:mm')
      }
      return date_return
    },
    getDifferencePrice(price_1,price_2) {
      var price = price_1 - price_2
      var cal_price = global_func.formatPrice(price)
      var mark = ''
      var data_return = ''
      if(price_1 < price_2) {
        mark = '+ '
      } else {
        mark = '- '
      }
      data_return = mark + cal_price
      return data_return
    },
    findTruckTypeName(truck_type_id) {
      var truck_type_name = ''
      var truck_id_split = truck_type_id.split('|')
      for (let index = 0; index < truck_id_split.length; index++) {
        const element = truck_id_split[index]
        if(index == 0) {
          for (let index2 = 0; index2 < this.dataTruckType.length; index2++) {
            const element2 = this.dataTruckType[index2]
            if(element == element2.id_model) {
              if(truck_type_name != '') {
                truck_type_name += ',..'
              } else {
                if(this.account_lang == 'en') {
                  truck_type_name += element2.name_model_en
                } else {
                  truck_type_name += element2.name_model_th
                }
              }

            }
          }
          if(truck_id_split.length > 1) {
            truck_type_name += ', ...'
          }
        }
      }
      return truck_type_name
    },
    findTwoPoint(p1,p2) {
      let point1_split = []
      let point2_split = []
      let data_return = ''
      if(p1 != undefined && p1 != null) {
        let point1 = p1.trim()
        point1_split = point1.split(' ')
        if(point1_split.length > 0) {
          let ck_str = point1_split[point1_split.length - 1]
          let index = 1
          if(!isNaN(ck_str)) {
            index++
          }
          let prov = point1_split[point1_split.length - index]
          let prov_split = prov.split('.')
          if(prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      if(p2 != undefined || p2 != null) {
        let point2 = p2.trim()
        point2_split = point2.split(' ')
        if(point2_split.length > 0) {
          let ck_str = point2_split[point2_split.length - 1]
          if(data_return != '') {
            data_return += ' - '
          }
          let index = 1
          if(!isNaN(ck_str)) {
            index++
          }
          let prov = point2_split[point2_split.length - index]
          let prov_split = prov.split('.')
          if(prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      return data_return
    },
    // action 
    openPlanDetail(option) {
      // console.log('option',option)
      this.clearMapFeature()
     
      let data_select = []
      for (let index = 0; index < this.plans_tracking_data.length; index++) {
        const element = this.plans_tracking_data[index]
        if(typeof option.plan_code !== 'undefined') {
          if(option.plan_code == element.plan_code) {
            data_select = element
          }
        }
      }
      this.plan_tracking_select = [data_select]
      this.fv_flag = [data_select][0].bookmark_flg == '1' ? true : false
      this.star_flag = [data_select][0].bookmark_flg == '1' ? true : false
      this.active_plan_detail = true
      this.block_ui_flg = false
      this.setPlanDataOnMap()
      this.group_id = null
      this.group_id = option.truck_type_group_id
      this.select_items = option.track_data
      this.select_items2 = option
      
      if(option.track_data != undefined && option.track_data.length > 0) {
        this.click_select_items = option.track_data[0].blackbox_id
      } else {
        this.click_select_items = null
      }
      if(this.click_select_items != null){
        this.startRealtime()
      }
    },
    closePlanDetail() {
      // console.log('option',option)
      this.clearMapFeature()
      if(this.active_plan_detail) {
        this.active_plan_detail = false
        this.block_ui_flg = true
      }
    },
    open_modal_detail(data){
      // console.log('data',data)
      this.$refs.plandetail.bt_detail(data)
    },
    bt_detail(data) {
      // console.log(data)
      var param_send = {provider_id:data.id_member_carrier } //id_member_goods_owner
      this.$refs.modalCompany.setProviderData(param_send)
      this.$bvModal.show('modal_company_detail')
    },
    search_plan() {
      this.getPlansTracking()
    },
    timeout_search_plan() {
      var that = this
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.getPlansTracking()
      }, 500)
    },
   
    clearMapFeature(){
      this.pinPointSource.clear()
      this.directionSource.clear()
    },
    // map action
    async setPlanDataOnMap() {

      await this.clearMapFeature()

      var plan_data = this.plan_tracking_select[0]
      this.setTimeLine(plan_data)
      // this.getTimeLine(plan_data)
      let departure_lat_lon = plan_data.data_detail.departure_lat_lon
      let arrival_lat_lon = plan_data.data_detail.arrival_lat_lon

      var departure = departure_lat_lon.split('|')
      var ariival = arrival_lat_lon.split('|')

      let coordinate = [parseFloat(departure[1]), parseFloat(departure[0])]
      let coordinate2 = [parseFloat(ariival[1]), parseFloat(ariival[0])]
      this.setPinPointLocation(coordinate, 0)
      this.setPinPointLocation(coordinate2, 1)
      // bank dev
      // let car_realrime = [100.45637117952708, 13.776951101623922]
      // await this.getDirectionsService_HereMap(coordinate, car_realrime)
      await this.getDirectionsService_HereMap(coordinate, coordinate2)
    },
    setPinPointLocation(coordinate, index) {
      this.activeLocation_index = index
      //set a new pinpoint at clicked coordinates
      var new_point = new ol.Feature({
        geometry: new geom.Point(proj.fromLonLat([coordinate[0], coordinate[1]])),
      })
      // check location index for select icon pinpoint
      var pinpoint = this.src
      if (this.activeLocation_index == 0) {
        pinpoint = this.src
      } else {
        pinpoint = this.src2
      }

      //set style of point
      new_point.setStyle(
        new style.Style({
          image: new style.Icon({
            // color: 'red',
            crossOrigin: 'anonymous',
            scale: 1,
            anchor: [0.5, 1],
            anchorOrigin: 'top-left',
            src: pinpoint,
          }),
        }),
      )

      this.pinPointSource.addFeature(new_point)
      // this.pinPointLayer[this.activeLocation_index] = new layer.Vector({
      //   source: new source.Vector({
      //     features: [new_point],
      //   }),
      // })

      // // set name of layer
      // this.pinPointLayer[this.activeLocation_index].set('name', 'point_' + this.activeLocation_index)
      // // add point on map , set new center point and zoom value
      // let new_center = proj.fromLonLat([coordinate[0], coordinate[1]])

      // // view.animate({
      // //   center: new_center,
      // //   duration: 2000,
      // // })

      // this.map.addLayer(this.pinPointLayer[this.activeLocation_index])

      // var zoom = this.map.getView().getZoom()
      // if (zoom < 12) {
      //   zoom = 12
      // }
      // this.map.getView().animate({
      //   center: new_center,
      //   zoom: zoom,
      //   duration: 800,
      // })
      // // set route when have 2 point already
      // var lo_1 = this.data_location[0]
      // var lo_2 = this.data_location[1]
      // let check1 = lo_1.LAT != null && lo_1.LAT != undefined && lo_1.LAT != ''
      // let check2 = lo_2.LAT != null && lo_2.LAT != undefined && lo_2.LAT != ''
      // if (check1 && check2 && index == 1) {
      //   this.getDirectionsService_HereMap(lo_1, lo_2)
      // }
    },

    getDirectionsService_HereMap_old(lo_1, lo_2) {
      var that = this
      var app_id = 'o9W16gheao25dXp9a9Kg' //'V78Ttyq1RSEy7KsExlfq'//'o9W16gheao25dXp9a9Kg'
      var app_code = 'Smo6mT91hk9YhqJSclXrSA' //'nqGgcND3Yoof9VDmsYc7Hg'//'Smo6mT91hk9YhqJSclXrSA'

      //    var app_id = 'rBwB1Fllq3IHyosgCFs5'
      //    var app_code = '-a-M-daFoGNJuMa7DjF4RA'

      // eslint-disable-next-line no-undef
      var platform = new H.service.Platform({
        app_id: app_id, //Key fluke
        app_code: app_code, //Key fluke
        'apikey': 'Due7AzLBaEnUBQSBPunaYDOLfk4fnPCQe1YuN6ys',
      })

      // var origin = lo_1.LAT + ',' + lo_1.LON
      // var destination = lo_2.LAT + ',' + lo_2.LON
      var origin = lo_1[1] + ',' + lo_1[0]
      var destination = lo_2[1] + ',' + lo_2[0]

      // eslint-disable-next-line no-unused-vars
      var routingParameters = {
        mode: 'fastest;truck',
        waypoint0: 'geo!' + origin, //'geo!50.1120423728813,8.68340740740811',
        waypoint1: 'geo!' + destination, //'geo!52.5309916298853,13.3846220493377',
        representation: 'display',
        routeattributes: 'labels',
        alternatives: 2,
      }
      var router = platform.getRoutingService()
      router.calculateRoute(
        routingParameters,
        function (result) {
          if (result.response.route) {
            // console.log('result.response',result.response)
            // // console.log('here map')
            // // console.log(result.response)
            that.directions_data = result.response
            that.calDirectionsHereMap()
          }
        },
        function (error) {
          console.log(error.message)
        },
      )
    },
    getDirectionsService_HereMap(lo_1, lo_2) {
      let that = this
      rhm.getDirectionsService({LAT : lo_1[1], LON : lo_1[0]}, {LAT : lo_2[1], LON : lo_2[0]}, e => {
        console.log("ee", e)
        that.directions_data = e
        that.calDirectionsHereMap()
      })
    },
    calDirectionsHereMap() {
      var locations = []
      var latlonData = this.directions_data.route[0].shape
      
      for (let index = 0; index < latlonData.length; index++) {
        let lat_lon = latlonData[index].split(',')
        locations[index] = [parseFloat(lat_lon[1]), parseFloat(lat_lon[0])]
      }
      // // console.log(locations)
      // for set new center
      // var half = (latlonData.length / 2).toFixed(0)
      // var halfRouteData = locations[half]

      // var latlan_c = proj.fromLonLat([parseFloat(halfRouteData[0]), parseFloat(halfRouteData[1])])
      // var zoom_c = 12
      // var distance = parseInt(this.total_distance)
      // if (distance < 40) {
      //   zoom_c = 13
      // } else if (distance < 70) {
      //   zoom_c = 12
      // } else if (distance < 100) {
      //   zoom_c = 11
      // } else if (distance < 150) {
      //   zoom_c = 10
      // } else if (distance < 300) {
      //   zoom_c = 9
      // } else if (distance < 500) {
      //   zoom_c = 8
      // } else if (distance < 750) {
      //   zoom_c = 7
      // } else {
      //   zoom_c = 6
      // }
      // // // console.log(distance)

      // this.map.getView().animate({
      //   center: latlan_c,
      //   zoom: zoom_c,
      //   duration: 800,
      // })

      var route = new geom.LineString(locations).transform('EPSG:4326', 'EPSG:3857')

      var new_point = new ol.Feature({
        geometry: route,
      })
      //set style of route line
      new_point.setStyle(
        new style.Style({
          stroke: new style.Stroke({
            width: 6,
            color: [0, 152, 209, 0.8],
          }),
        }),
      )
      var that = this
      setTimeout(function() {
        // that.map.addLayer(that.directionLayer)
        that.directionSource.addFeature(new_point)
        that.moveLayerBefore(that.findLayer('pin_point'), that.findLayer('route'), that.map.getLayers())
      }, 1000)
    },
    moveLayerBefore(old_idx, new_idx, layer) {
      if (old_idx === -1 || new_idx === -1) {
        return false
      }
      var that = this
      // layer.removeAt(old_idx)
      layer = that.map.getLayers().removeAt(old_idx)
      // layer = this.map
      this.map.getLayers().insertAt(new_idx, layer)
    },
    findLayer(layer_name) {
      var layer_idx = -1
      var layerArr = this.map.getLayers().getArray()
      for (let index = 0; index < layerArr.length; index++) {
        const element = layerArr[index]
        var this_layer_name = element.get('name')
        if (this_layer_name == layer_name) {
          layer_idx = index
        }
      }
      return layer_idx
    },
    async removeLayerByName(name) {
      var layer = this.map.getLayers().getArray()
      await this.map.removeLayer(layer[this.findLayer(name)])
    },
    // async removePlanMapLayer() {
    //   if (this.findLayer('route')) {
    //     await this.removeLayerByName('route')
    //   }
    //   if (this.findLayer('point_0')) {
    //     await this.removeLayerByName('point_0')
    //   }
    //   if (this.findLayer('point_1')) {
    //     await this.removeLayerByName('point_1')
    //   }
    // },
    // timeline
    getTimeLine(data) {
      var that = this
      service
        .postService('getTimeLineTracking', {
          plan_data: data,
        })
        .then(rp => {
          // // console.log(rp)
        })
    },
    setTimeLine(plan_data) {
      let data_select = []
      for (let index = 0; index < this.plans_tracking_data.length; index++) {
        const element = this.plans_tracking_data[index]
        if(typeof plan_data.plan_code !== 'undefined') {
          if(plan_data.plan_code == element.plan_code) {
            data_select = element
          }
        }
      }
      let data = data_select.plan_all_data
      this.arrlocation = []
      for (let index = 0; index <= 4; index++) {
        let data_push = {}
        const element = data
        // // console.log(element)
        if(index == 0) {
          if(element.accept_flg != null && element.accept_flg == '1') {
            if(element.departure_address != null && element.departure_time != null) {
              data_push = {
                name: element.departure_address,
                date_appointment: element.accept_time,
                status: 'รอดำเนินการ',
              }
              this.arrlocation.push(data_push)
            } 
          }
        } 
        if(index == 1) {
          if(element.start_plan_flg != null && element.start_plan_flg == '1') {
            if(element.departure_address != null && element.start_plan_time != null) {
              data_push = {
                name: element.departure_address,
                date_appointment: element.start_plan_time,
                status: 'อยู่ระหว่างขนส่ง',
              }
              this.arrlocation.push(data_push)
            } 
          } 
        }
        if(index == 2) {
          if(element.end_plan_flg != null && element.end_plan_flg == '1') {
            if(element.arrival_address != null && element.end_plan_time != null) {
              data_push = {
                name: element.arrival_address,
                date_appointment: element.end_plan_time,
                status: 'ดำเนินการเสร็จแล้ว',
              }
              this.arrlocation.push(data_push)
            } 
          } 
        }
        if(index == 3) {
          if(element.order_status != null && element.order_status == '5') {
            if(element.arrival_address != null && element.fin_plan_time != null) {
              data_push = {
                name: element.arrival_address,
                date_appointment: element.fin_plan_time,
                status: 'ปิดงาน',
              }
              this.arrlocation.push(data_push)
            } 
          } 
        }
      }
      // // console.log(this.arrlocation)
    },
    // plan action
    askFinPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + 'จบแผนงานนี้' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          // this.sendFinPlan(option)
          this.$bvModal.hide('modal_proof')
          this.openReviewModal(option)
        } else {
          return false
        }
      })
    },
    askCancelPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + 'ยกเลิกงานนี้' + '<br />' + 'ใช่หรือไม่' + '<br /><br />' + '*** การยกเลิกงานอาจจะมีค่าปรับเกิดขึ้น<br />ขึ้นอยู่กับผู้ให้บริการขนส่ง',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendCancelPlan(option)
        } else {
          return false
        }
      })
    },
    sendFinPlan(estimate) {
      var that = this
      service
        .postService('sendFinPlan', {
          estimate_data: estimate,
          account_data: that.account_data,
          mode: 'tracking',
        })
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            service
              .postService('deleteMatchCar', {
                id_carrier: estimate.id_carrier,
              })
              // eslint-disable-next-line no-unused-vars
              .then(rp => {
                if(rp.code == '200') {
                  global_func.alertMessage(that.lang.alert.fin_plan, '', 'success')
                  this.plan_tracking_select[0].order_status_name = this.getPlanStatus('5')
                  this.plan_tracking_select[0].order_status = '5'
                  this.plan_tracking_select[0].plan_all_data.order_status = '5'
                  setTimeout(function() {
                    that.getPlansTracking()
                  }, 500)
                  setTimeout(function() {
                    var plan_data = that.plan_tracking_select[0]
                    // // console.log(plan_data)
                    that.setTimeLine(plan_data)
                  }, 1000)
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: that.lang.alert.del_truck_failed,
                    confirmButtonText: that.lang.btn.close,
                  })
                }
              })
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
          
        })
    },
    sendCancelPlan(estimate) {
      var that = this
      service
        .postService('sendCancelPlan', {
          estimate_data: estimate.plan_all_data,
          mode: 'tracking',
          account_data: this.account_data,
        })
        .then(rp => {
          // // console.log(rp)
          if(rp.code == '200') {
            global_func.alertMessage(that.lang.alert.cancel, '', 'success')
            this.openPlanDetail()
            setTimeout(function() {
              that.getPlansTracking()
            }, 500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    openReviewModal(param_send) {
      this.$refs.modalReview.setProviderData(param_send)
    },
    getSubscribeData() {
      service
        .postService('getSubscribeData', {
          account_data : this.account_data,
        })
        .then(rp => {
          // // console.log(rp)
          if(rp.status == 'ok') {
            this.subscribe_data = []
            this.subscribe_data = rp.data
          }
          // // console.log(this.subscribe_data)
        })
    },
    findStarFlag(id_member) {
      let subscribe_data = this.subscribe_data
      let flag = '0'
      for (let index = 0; index < subscribe_data.length; index++) {
        const element = subscribe_data[index]
        if(element.provider_id == id_member) {
          flag = '1'
        }
      }
      return flag
    },
    getAllProfilePicture(all_provider_id) {
      let provider_id = [...new Set(all_provider_id)]
      // console.log(provider_id)
      service
        .postService('getAllProfilePicture', {
          id_member_data: provider_id,
        })
        .then(rp => {
          // console.log(rp)
          this.setProfilePicture(rp.data)
        })
    },
    setProfilePicture(profile_picture_data) {
      let temp_plans_tracking_data = this.plans_tracking_data
      this.plans_tracking_data = []
      for (let index = 0; index < temp_plans_tracking_data.length; index++) {
        let element = temp_plans_tracking_data[index]
        // console.log(element)
        const blob = profile_picture_data.filter((e) => {
          return e.id_member == element.plan_all_data.id_member_carrier
        })
        element.pic_profile = blob[0].pic_profile
        this.plans_tracking_data.push(element)
      }
    },
    openProofModal(param_send) {
      this.$refs.modalProof.getProofOfCarrier(param_send)
    },
  },
}
</script>

<template>
  <Layout>
    <b-row>
      <b-col lg="4">
        <div class="card-body" style="height:100vh;">
          <div>
            <div v-show="!active_plan_detail">
              <div style="padding: 10px 0px 10px 0px; background: #FFFFFF 0% 0% no-repeat padding-box;">
                <b-row>
                  <b-col lg="6" class="col-margin-bot-1">
                    <select v-model="sort_by_plans_status" class="form-control" @change="search_plan()">
                      <option value="0">สถานะทั้งหมด</option>
                      <option value="1">รอเสนองาน</option>
                      <option value="2">รอดำเนินการ</option>
                      <option value="3">อยู่ระหว่างขนส่ง</option>
                      <option value="4">ดำเนินการเสร็จแล้ว</option>
                      <option value="5">ปิดงาน</option>
                    </select>
                  </b-col>
                  <b-col lg="6" class="col-margin-bot-1">
                    <div class="input-group prepend">
                      <b-form-input id="search_plans_text" v-model="search_plans_text" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_plans" @keyup="timeout_search_plan()" />
                      <div class="input-group-prepend custom-input-group-prepend">
                        <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" @click="search_plan()" /></span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <simplebar class="simplebar-plan-display" style="height:calc(100vh - 200px); background: #FFFFFF 0% 0% no-repeat padding-box;padding:0;">
                <div role="tablist">
                  <b-card v-for="(option,index) in plans_tracking_data" :key="index" class="plans-row-data-tracking col-margin-bot-1" style="position: relative;padding:0;">
                    <div @click="openPlanDetail(option)">
                      <b-row>
                        <b-col>
                          <span style="font-size: 20px;font-weight: 500;">
                            {{ option.plan_code }}
                          </span>
                          <span class="font-color-blue" style="font-weight: 400;">
                            ({{ option.order_status_name }})
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col class="text-muted" style="font-size:73%;">
                          <span>
                            เริ่มงาน 
                          </span>
                          <span>
                            {{ option.departure_time | moment("dd DD/MM/YYYY HH:mm") }} น.
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col class="d-flex justify-content-start">
                          <span>
                            สินค้า :
                          </span>
                        </b-col>
                        <b-col class="d-flex justify-content-end">
                          <span class=" font-color-blue">
                            {{ option.goods_type_name }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col class="d-flex justify-content-start">
                          <span class="">
                            จุดรับ - ส่งสินค้า :
                          </span>
                        </b-col>
                        <b-col class="d-flex justify-content-end">
                          <span class=" font-color-blue">
                            {{ option.two_point_name }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col class="d-flex justify-content-start">
                          <span class="">
                            ประเภทรถ :
                          </span>
                        </b-col>
                        <b-col class="d-flex justify-content-end">
                          <img v-if="option.truck_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="40px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="40px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="40px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="40px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="40px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="40px" style="margin-right: 20px" />
                          <span class=" font-color-blue">
                            {{ option.truck_type_name }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <hr />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="1" style="margin-right: 10px">
                          <div class="d-flex align-items-center">
                            <img v-if="option.pic_profile" :src="option.pic_profile" height="30px" />
                            <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" height="30px" />
                          </div>
                        </b-col>
                        <b-col lg="10" class="d-flex align-items-center">
                          <span class="">
                            {{ option.carrier_name }}
                          </span>
                        </b-col>
                      </b-row>
                    </div>
                    <div style="text-align:right;position: absolute;top:20px;right:20px;">
                      <i :class="option.bookmark_flg == 1 ? 'ri-bookmark-fill': 'ri-bookmark-line'" :style="option.bookmark_flg == 1 ? 'color:#FAAB00;font-size:20px;' : 'font-size:20px;'" @click="update_favorite(option)" />
                    </div>
                  </b-card>
                </div>
              </simplebar>
            </div>
            <div v-show="active_plan_detail">
              <b-row class="col-margin-bot-1 ">
                <b-col lg="3">
                  <span class="d-flex justify-content-left btn_back_blue" @click="clearData(),closePlanDetail()"><i class="ri-arrow-left-line" style="margin-right:5px;" />ย้อนกลับ</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div v-for="(option2,index) in plan_tracking_select" :key="index" class="col-margin-bot-1">
                    <b-row>
                      <b-col style="display:flex;">
                        <div style="width:100%;">
                          <span class="font-size-24" style="font-weight: 500;">
                            {{ option2.plan_code }}
                          </span>
                          <span class=" font-color-blue" style="font-weight: 400;">
                            ({{ option2.order_status_name }})
                          </span>
                        </div>
                        <div style="width:100%;text-align:right;">
                          <i :class="fv_flag == true ? 'ri-bookmark-fill': 'ri-bookmark-line'" :style="fv_flag == true ? 'color:#FAAB00;font-size:20px;cursor:pointer;' : 'font-size:20px;cursor:pointer;'" @click="update_favorite(option2,'in')" />
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="font-size-14">
                          เริ่มงาน 
                        </span>
                        <span class="font-size-14">
                          {{ option2.departure_time | moment("dd DD/MM/YYYY HH:mm") }} น.
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col class="d-flex justify-content-start">
                        <span class="">
                          สินค้า :
                        </span>
                      </b-col>
                      <b-col class="d-flex justify-content-end">
                        <span class=" font-color-blue">
                          {{ option2.goods_type_name }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col class="d-flex justify-content-start">
                        <span class="">
                          จุดรับ - ส่งสินค้า :
                        </span>
                      </b-col>
                      <b-col class="d-flex justify-content-end">
                        <span class=" font-color-blue">
                          {{ option2.two_point_name }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col class="d-flex justify-content-start">
                        <span class="">
                          ประเภทรถ :
                        </span>
                      </b-col>
                      <b-col class="d-flex justify-content-end">
                        <img v-if="option2.truck_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="40px" style="margin-right: 20px" />
                        <img v-else-if="option2.truck_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="40px" style="margin-right: 20px" />
                        <img v-else-if="option2.truck_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="40px" style="margin-right: 20px" />
                        <img v-else-if="option2.truck_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="40px" style="margin-right: 20px" />
                        <img v-else-if="option2.truck_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="40px" style="margin-right: 20px" />
                        <img v-else-if="option2.truck_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="40px" style="margin-right: 20px" />
                        <span class=" font-color-blue">
                          {{ option2.truck_type_name }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col class="d-flex justify-content-start">
                        <span class="">
                          ข้อมูลแผนงาน :
                        </span>
                      </b-col>
                      <b-col class="d-flex justify-content-end">
                        <span class=" font-link" @click="open_modal_detail(option2.data_detail)">
                          รายละเอียด
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <hr />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="9" class="d-flex align-items-center">
                        <div class="d-flex align-items-center" style="margin-right: 10px">
                          <div style="display:flex;">
                            <div style="margin-right:10px;">
                              <i :class="option2.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option2.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:20px;' : 'cursor:pointer;font-size:20px;'" @click="updateStar(option2, index)" />
                            </div>
                            <div>
                              <img v-if="option2.pic_profile" :src="option2.pic_profile" height="30px" />
                              <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" height="30px" />
                            </div>
                          </div>
                        </div>
                        <span class="">
                          {{ option2.carrier_name }}
                        </span>
                      </b-col>
                      <b-col lg="3" class="d-flex align-items-center justify-content-end">
                        <span class=" font-link" @click="bt_detail(option2.plan_all_data)">
                          ข้อมูลบริษัท
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <hr />
                        <select v-if="click_select_items != null" v-model="click_select_items" style="margin-bottom:20px;" class="form-control" @change="startRealtime()">
                          <option v-for="option in select_items" :key="option.blackbox_id" :value="option.blackbox_id">
                            {{ option.truck_name }} 
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                    <b-row v-for="(option3,index3) in arrlocation" :key="index3">
                      <b-col lg="2">
                        <b-row>
                          <b-col style="text-align: center; font-size: 0.8rem;">
                            <span>
                              {{ option3.date_appointment | moment("DD/MM/YYYY") }}
                            </span>
                          </b-col>
                          <b-col style="text-align: center; font-size: 0.8rem;">
                            <span>
                              {{ option3.date_appointment | moment("HH:mm") }}
                            </span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col lg="10" class="d-flex align-items-start">
                        <ul class="list-unstyled activity-wid" style="margin-top: 0px">
                          <li class="activity-list">
                            <div class="activity-icon avatar-xs">
                              <span class="avatar-title bg-soft-primary text-primary rounded-circle" style="align-items: flex-start">
                                <i :class="`ri-map-pin-fill`" />
                              </span>
                            </div>
                            <template v-if="index3 != 0 && index3 != 3">
                              <span>
                                {{ option3.name }}
                              </span>
                              <br />
                            </template>
                            <span>
                              ({{ option3.status }})
                            </span>
                            <br />
                            <br />
                          </li>
                        </ul>
                      </b-col>
                      <br />
                    </b-row>
                    <b-row v-if="option2.order_status != '5'">
                      <b-col v-if="option2.order_status == '4'">
                        <div class="d-flex justify-content-center big_btn_green" @click="openProofModal(option2)">
                          <span class="margin-top-bot-auto">{{ lang.btn.proof_fin_job }}</span>
                        </div>
                      </b-col>
                      <b-col v-else>
                        <div class="d-flex justify-content-center big_btn_green_dis">
                          <span class="margin-top-bot-auto">{{ lang.btn.proof_fin_job }}</span>
                        </div>
                      </b-col>
                      <b-col v-if="option2.order_status == '0' || option2.order_status == '1' || option2.order_status == '2'">
                        <div class="d-flex justify-content-center big_btn_red" @click="askCancelPlan(option2)">
                          <span class="margin-top-bot-auto">{{ lang.btn.cancel_job }}</span>
                        </div> 
                      </b-col>
                      <b-col v-else>
                        <div class="d-flex justify-content-center big_btn_red_dis">
                          <span class="margin-top-bot-auto">{{ lang.btn.cancel_job }}</span>
                        </div> 
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col ref="map" lg="8" class="col-main-menu-tran">
        <BlockUI v-show="block_ui_flg" />
        <div id="map_1" ref="map_1" />
      </b-col>
    </b-row>
    <!-- offer_detail_data -->
    <PlanDetail ref="plandetail" :obj="{}" /> 
    <!-- company detail -->
    <ModalCompany ref="modalCompany" :obj="{}" />
    <ModalReview ref="modalReview" :obj="{}" @fin-plan="sendFinPlan" />
    <ModalProof ref="modalProof" :obj="{}" @fin-plan="askFinPlan" />
  </Layout>
</template>
<style>
@import '../../../../node_modules/ol-layerswitcher/dist/ol-layerswitcher.css';
@media only screen and (min-width: 500px) {
  body {
    overflow: scroll 
  }
}
@media only screen and (min-width: 1200px) {
  body {
    overflow: hidden 
  }
}
.h-100vh {
  height: 100% !important;
}
hr {
  border: 1px solid #e4e4e4;
}
#map_1 {
  height: calc(100vh - 115px); /* 100% of the viewport height - navbar height */
}
.col-main-menu-tran {
  padding: 0px;
}
.plans-row-data-tracking {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  padding: 1rem;
}
.plans-row-data-tracking:hover {
  transition: 0.3s;
  border: 1px solid #03AEEF;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.col-margin-bot-1 {
  margin-bottom: 0.6rem;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}
</style>
