<script>
import i18n from '@/i18n'
import service from '@/server/http_service'
import VueEasyLightbox from 'vue-easy-lightbox'
import vueDropzone from 'vue2-dropzone'
import global_func from '@/global_function'

/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง บ',
    meta: [{ name: 'description' }],
  },
  components: { VueEasyLightbox, vueDropzone },
  props: {
    obj: {
      type: Object,
      default: () => {
        return { message: 'no obj' }
      },
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('company_detail.tab_review')
    var lang_obj2 = i18n.t('upload_file_modal')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      lang2: lang_obj2,
      provider_id: '',
      active_tab: 0,
      rating: 3,
      comment_text : '',
      estimate_data : {},
      id_assesment: '',
      // company info part
      company_raw_data: [],
      company_data: {},
      // file
      file_raw_data: [],
      pic_data: [],
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      lightboximgs_review: [],
      lightboximgs_review_index: [{}, {}, {}],
      lang_upload_title: '',
      lang_upload_sub_title: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        maxFilesize: 5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        maxFiles: 3,
        acceptedFiles: '',
      },
    }
  },
  watch: {},
  beforeMount() {},
  mounted() {},
  methods: {
    bt_detail() {
      var that = this
      that.$bvModal.show('modal_review')
    },
    close_modal() {
      this.$bvModal.hide('modal_review')
    },
    showImg(index) {
      this.index = index
      this.visible = true
    },
    setProviderData(data) {
      var that = this
      // console.log(data)
      
      // set default
      this.rating = 3
      this.comment_text = ''
      this.estimate_data = {}

      if(data.estimate_data !== undefined) {
        // transportation_plan/plans_display
        this.estimate_data = data.estimate_data
        this.provider_id = data.provider_id
      } else {
        // transportation_plan/tracking
        this.estimate_data = data.plan_all_data
        this.provider_id = data.plan_all_data.id_member_carrier
      }

      // console.log(data)
      // console.log(this.provider_id)
      that.$bvModal.show('modal_review')
      setTimeout(() => {
        that.getCompanyDataById()
      }, 300)
    },
    getCompanyDataById() {
      var that = this
      service
        .postService('getCompanyDataById', {
          provider_id: that.provider_id,
        })
        .then(rp => {
          console.log(rp)
          this.company_raw_data = []
          this.file_raw_data = []
          this.company_raw_data = rp.company_data[0]
          this.file_raw_data = rp.file_data
          setTimeout(() => {
            that.convert_raw_data()
          }, 300)
        })
    },
    convert_raw_data() {
      var data = this.company_raw_data
      var element = data
      // console.log(element)
      this.company_data = {
        company_id: element.id_member,
        company_name: element.mover_name,
        company_type: element[this.changeLangData('business_name')],
        company_tel: element.mover_tel,
        company_email: element.mailaddr,
        company_address: element.mover_address1,
        star_score: '-',
        lover_score: '-',
      }

      // var data2 = this.file_raw_data
      // var pic_obj = []
      // var pic_obj2 = []
      // for (let index = 0; index < data2.length; index++) {
      //   const element2 = data2[index]
      //   if (element2.title == 'pic_mover') {
      //     // var path = require('/../file_upload_dtc_backhaul/images/pic_mover/' + element2.filename)
      //     var path = element2.blob
      //     pic_obj.push(path)
      //   }
      //   if (element2.title == 'profile') {
      //     // var path = require('/../file_upload_dtc_backhaul/images/pic_mover/' + element2.filename)
      //     var path2 = element2.blob
      //     pic_obj2.push(path2)
      //   }
      // }
      // this.lightboximgs_review = pic_obj
      // this.pic_data = pic_obj2
    },
    changeLangData(func) {
      var data_return = ''
      if (this.account_lang == 'th') {
        if (func == 'business_name') {
          data_return = 'business_type_name_th'
        } else if (func == 'vehicle_name') {
          data_return = 'name_group_th'
        } else if (func == 'geo_name') {
          data_return = 'name'
        }
      }
      return data_return
    },
    setRating(rating) {
      console.log(rating)
    },
    active() {
      this.$bvModal.show('modal_upload_file_review')
      this.setConfigUploader('review')
    },
    setConfigUploader(mode, index) {
      // console.log(mode)
      // console.log(index)
      this.file_mode = mode
      this.index = index
      if(this.file_mode == 'profile') {
        this.lang_upload_title = this.lang2.profile.title
        this.lang_upload_sub_title = this.lang2.profile.sub_title
        this.dropzoneOptions.acceptedFiles = 'image/*'
      } else if(this.file_mode == 'id_card') {
        this.lang_upload_title = this.lang.id_card.title
        this.lang_upload_sub_title = this.lang.id_card.sub_title
        this.dropzoneOptions.acceptedFiles = 'image/*,application/pdf'
      } else if (this.file_mode == 'review') {
        this.lang_upload_title = this.lang2.review.title
        this.lang_upload_sub_title = this.lang2.review.sub_title
        this.dropzoneOptions.acceptedFiles = 'image/*'
      }
    },
    async check_fileUpload() {
      var file = this.$refs.dropzone_file_upload.dropzone.files
      var that = this
      var flag = 0
      if(file.length > 0) {
        that.file_upload = []
        for (let index = 0; index < file.length; index++) {
          let reader = new FileReader()
          const rowFile = file[index]
          // console.log(rowFile)
          await reader.addEventListener(
            'load',
            () => {
              let dataPush = {
                name: rowFile.name,
                data: reader.result,
                type: rowFile.type,
              }
              that.file_upload.push(dataPush)
            },
            false,
          )
          if(rowFile.status == 'success'){ 
            reader.readAsDataURL(rowFile)
          } else {
            flag++
          }
        }
      }
      if(flag == 0) {
        // console.log(this.file_upload)
        setTimeout(function() {
          that.setPreviewPicture()
        }, 500)
      } else {
        global_func.alertMessage(this.lang.alert_msg.error_upload, '', 'warning')
      }
    },
    setPreviewPicture() {
      this.lightboximgs_review = []
      for (let index = 0; index < this.file_upload.length; index++) {
        const row = this.file_upload[index]
        this.lightboximgs_review.push(row.data)
      }
      // console.log(this.lightboximgs_review)
      this.$bvModal.hide('modal_upload_file_review')
    },
    sendReview() {
      // console.log(this.file_upload)
      // console.log(this.file_mode)
      // console.log(this.index)
      if(this.file_mode == '' || this.file_mode == undefined) {
        this.file_mode = 'review'
      }
      service
        .postService('sendReviewData', {
          account_data: this.account_data,
          company_data: this.company_data,
          file_upload: this.file_upload,
          comment_text: this.comment_text,
          rating_review: this.rating,
          id_order: this.estimate_data.id_order,
          id_carrier: this.estimate_data.id_carrier,
          mode: this.file_mode,
        })
        .then(rp => {
          // console.log(rp)
          let status = rp.status
          if(status == 'ok') {
            // global_func.alertMessage(this.lang2.alert_msg.review_success, '', 'success')
            this.$bvModal.hide('modal_review')
            this.$emit('fin-plan', this.estimate_data)
          } else {
            global_func.alertMessage(this.lang2.alert_msg.error_upload, '', 'warning')
          }
        })
    },
  },
}
</script>

<template>
  <div>
    <b-modal id="modal_review" centered size="lg" :title="lang.title" title-class="font-size-24" hide-footer>
      <div class="modal-review-body-custom">
        <template>
          <b-row>
            <b-col lg="2" class="d-flex justify-content-end">
              <img v-if="pic_data != undefined && pic_data.length != 0" :src="pic_data" class="mr-3 rounded-circle avatar-lg" alt="user-pic" />
              <img v-else src="@/assets/images/profile/Group 3361.png" class="mr-3 rounded-circle avatar-lg" alt="user-pic" />
            </b-col>
            <b-col lg="8">
              <b-row>
                <b-col lg="12" style="margin-top: 0.7rem;">
                  <div class="font-size-24 font-weight-medium">
                    <span> {{ company_data.company_name }} </span>
                  </div>
                </b-col>
                <b-col lg="12">
                  <div class="font-size-18 font-weight-light">
                    <span> {{ company_data.company_address }} </span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr />
            </b-col>
          </b-row>
          <br />
          <br />
          <b-row>
            <b-col lg="2" />
            <b-col lg="8">
              <b-row>
                <b-form-rating v-model="rating" no-border size="lg">
                  <template v-slot:icon-empty>
                    <b-icon icon="heart" scale="4" color="#B0BAC4" />
                  </template>
                  <template v-slot:icon-half>
                    <b-icon icon="heart-half" scale="4" color="#FF718B" />
                  </template>
                  <template v-slot:icon-full>
                    <b-icon icon="heart-fill" scale="4" color="#FF718B" />
                  </template>
                </b-form-rating>
              </b-row>
              <br />
              <b-row class="font-size-18">
                <b-col lg="4">
                  <span style="margin-left: 17%">
                    {{ lang.rating.bad }}
                  </span>
                </b-col>
                <b-col lg="4" />
                <b-col lg="4">
                  <span style="margin-left: 45%">
                    {{ lang.rating.good }}
                  </span>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="2" />
          </b-row>
          <br />
          <br />
          <b-row>
            <b-col>
              <b-form-textarea id="textarea-rows" v-model="comment_text" :placeholder="lang.comment" rows="5" />
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col>
              <div class="popup-gallery d-flex flex-fill justify-content-center">
                <b-row>
                  <b-col v-for="(item, index3) in lightboximgs_review_index" :key="index3" lg="4">
                    <a v-if="lightboximgs_review[index3] != undefined" class="float-left" @click="() => showImg(index3)">
                      <div class="img-fluid">
                        <img :src="lightboximgs_review[index3]" alt width="200" />
                      </div>
                    </a>
                    <div v-else class="img-fluid">
                      <img src="@/assets/images/profile/temp/id_card_temp.png" alt width="200" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col>
              <div class="d-flex flex-fill justify-content-center">
                <b-col lg="2">
                  <div class="form-group d-flex justify-content-center align-items-center big_btn_blue" @click="active()">
                    <span class="font-size-16">
                      {{ lang2.upload }}
                    </span>
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-fill justify-content-center">
                <b-col lg="3">
                  <div class="form-group d-flex justify-content-center align-items-center big_btn_green" @click="sendReview()">
                    <span class="font-size-16">
                      {{ lang.btn.ok }}
                    </span>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="form-group d-flex justify-content-center align-items-center big_btn_red_white_bg" @click="close_modal()">
                    <span class="font-size-16">
                      {{ lang.btn.cancel }}
                    </span>
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
          <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightboximgs_review" @hide="visible = false" />
        </template>
      </div>
    </b-modal>
    <b-modal id="modal_upload_file_review" centered size="md" :title="lang2.title" title-class="font-size-24" hide-footer>
      <div class="modal_upload_file-custom">
        <b-row>
          <b-col>
            <vue-dropzone id="dropzone_file_upload" ref="dropzone_file_upload" :options="dropzoneOptions" :use-custom-slot="true">
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">{{ lang_upload_title }}</h3>
                <div class="subtitle">{{ lang_upload_sub_title }}</div>
              </div>
            </vue-dropzone>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="3" />
          <b-col lg="6">
            <div class="form-group d-flex justify-content-center align-items-center big_btn_blue" @click="check_fileUpload()">
              <span class="font-size-16">
                {{ lang2.ok }}
              </span>
            </div>
          </b-col>
          <b-col lg="3" />
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.modal-review-body-custom {
  padding: 1rem 1rem;
}
.modal-scrollbar {
  overflow: hidden scroll;
}

.modal-scrollbar {
  overflow: auto;
}
hr {
  border: 1px solid #e4e4e4;
}
.modal_upload_file-custom {
  padding: 1rem 1rem;
}
.vue-dropzone:hover .dropzone-custom-title {
  color: #03aeef;
}
.vue-dropzone > .dz-preview .dz-remove {
  position: absolute;
  z-index: 30;
  color: #fff;
  margin-left: 5px;
  padding: 5px;
  top: inherit;
  bottom: 15px;
  border: 2px #fff solid;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 800;
  letter-spacing: 1.1px;
  opacity: 0;
}
</style>
